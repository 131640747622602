<template>
  <section v-if="!isReady">
    <div class="loading">
      <hub-icon name="loading" spin size="xxlg"></hub-icon>
    </div>
  </section>
  <section v-else style="overflow-y: scroll">
    <div ref="streamRootRef" class="messages-stream">
      <ul v-if="collection?.length" class="messages-list">
        <li v-for="item of collection" :key="item.id">
          <hub-message v-bind="item" />
        </li>

        <hub-observe v-if="total > collection.length" :get-root-ref="() => $refs['streamRootRef']" @intersect="more">
          <hub-button variant="text" @click="more">more<hub-icon name="refresh"></hub-icon></hub-button>
        </hub-observe>
      </ul>
      <div v-else class="no-data"><label>You can start conversation by sending the first message</label></div>

      <footer>
        <hub-send-message :invention-id="$route.params.id" @add-task="showAddTaskPane"></hub-send-message>
      </footer>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import Icon from '../../common/Icon.vue';
import ChatMessage from '../widgets/ChatMessage.vue';
import SendMessage from './SendMessage';
import Observer from '../Observer.vue';
import Button from '../../common/Button.vue';

export default {
  components: {
    'hub-icon': Icon,
    'hub-button': Button,

    'hub-send-message': SendMessage,
    'hub-message': ChatMessage,
    'hub-observe': Observer
  },
  data() {
    return {
      isReady: false,
      to: null,
      storeSubscription: null
    };
  },
  computed: {
    ...mapGetters({
      collection: 'messages/collection'
    }),
    ...mapState({
      invention: s => s.inventions.item,
      isGetStreamRequestPending: s => s.messages.isRequestPending,
      total: s => s.messages.total
    })
  },
  watch: {
    'collection.length'() {
      if (!this.$refs.streamRootRef) {
        return;
      }
      const list = this.$refs.streamRootRef.querySelector('ul');
      const scrollHeight = list?.scrollTop;
      if (scrollHeight > -100 && scrollHeight < 100) {
        this.$nextTick(() => {
          list.scrollTop = 0;
        });
      }
    }
  },
  async unmounted() {
    this.$store.dispatch('messages/unsubscribe', { inventionId: this.invention?.id });
    await this.$store.dispatch('messages/disconnect');
    this.storeSubscription && this.storeSubscription();
    this.storeSubscription = null;
  },
  async created() {
    this.isReady = false;
    await this.$store.dispatch('messages/connect');

    this.$store.dispatch('messages/subscribe', { inventionId: this.$route.params.id });

    try {
      this.to = new Date();

      await this.$store.dispatch('messages/load', { id: this.$route.params.id });
    } finally {
      this.isReady = true;
    }
    this.storeSubscription = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'messages/IS_ONLINE_CHANGED') {
        if (mutation.payload.isOnline && this.$route.params.id) {
          this.$store.dispatch('messages/subscribe', { inventionId: this.$route.params.id });
        }
      }
    });
  },
  methods: {
    async more() {
      await this.$store.dispatch('messages/load', { id: this.$route.params.id, skip: this.collection.length, to: this.to });
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.messages-stream.messages-stream {
  height: 100%;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;

  grid-template-rows: 1fr 50px;
  .messages-list {
    padding: 0 0.2rem;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
    flex: 1;
  }
  > footer {
    height: 50px;
    padding: 0 1.25rem;
  }
  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-style: italic;
  }
}
</style>
